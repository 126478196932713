import React from "react";
import { Container, Section, Wrapper } from "components/style";
import ByHandPackageIcon from "assets/svgs/graphic-design-by-hand/by-hand_01.svg";
import ByHandPosterIcon from "assets/svgs/graphic-design-by-hand/by-hand_02.svg";
import ByHandAntiDesignIcon from "assets/svgs/graphic-design-by-hand/by-hand_03.svg";
import * as SC from "./style";

const ForWhomItem = ({ icon, title, text }) => (
  <SC.Item>
    {icon}
    <SC.ItemText>
      <b>{title}</b>
      {text}
    </SC.ItemText>
  </SC.Item>
);

const ByHandForWhom = () => {
  const items = [
    {
      icon: <ByHandPackageIcon />,
      title: "Дизайнеру упаковки",
      text: "Чтобы научиться создавать ручной, крафтовый дизайн, который удивит даже самого требовательного клиента",
    },
    {
      icon: <ByHandPosterIcon />,
      title: "Дизайнер плакатов и афиш",
      text: "Чтобы обогатить используемые приёмы в дизайне, научиться создавать «эмоциональные» работы",
    },
    {
      icon: <ByHandAntiDesignIcon />,
      title: "Анти-дизайнеры",
      text: "Если вы устали от коммерческого дизайна, то тут вы сможете позволить себе смелые визуальные эксперименты.",
    },
  ];

  return (
    <Section>
      <Container>
        <Wrapper>
          <SC.Title>
            Для кого этот мастер-класс<span>?</span>
          </SC.Title>
          <SC.InfoWrapper>
            {items.map(({ title, text, icon }, idx) => (
              <ForWhomItem key={idx} title={title} text={text} icon={icon} />
            ))}
          </SC.InfoWrapper>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default ByHandForWhom;
