import React from "react";
import { Container, Section } from "components/style";
import Masonry from "components/pages/course-page/portfolio/Masonry";
import * as SC from "./style";

const FoundationPortfolio = ({
  portfolio,
  isShowMoreParam,
  courseTitle,
  customTitle,
  fixedHeight,
}) => (
  <Section>
    <Container>
      <SC.Wrapper>
        <SC.SectionTitle>
          {customTitle ?? "Некоторые работы учеников"}
        </SC.SectionTitle>
        <Masonry
          portfolio={portfolio}
          courseTitle={courseTitle}
          isShowMoreParam={isShowMoreParam}
          customClass="portfolio-image-zoom"
          fixedHeight={fixedHeight}
        />
      </SC.Wrapper>
    </Container>
  </Section>
);

export default FoundationPortfolio;
