// react/gatsby imports
import React from "react";
// components
import Layout from "components/layout";
import SEO from "components/layout/seo";
import FoundationPortfolio from "components/pages/foundation-of-graphic-design/foundation-portfolio";
import CoursePriceRange from "components/pages/course-page/price-range";
import ByHandForWhom from "components/pages/graphic-design-by-hand/for-whom";
import ByHandAuthors from "components/pages/graphic-design-by-hand/authors";
import ByHandOffer from "components/pages/graphic-design-by-hand/offer";
import PaymentChoices from "components/pages/payment-choices";
// data
import localData from "data/pages/graphic-design-by-hand";
// assets
import ogImage from "assets/images/seo/foundation-of-graphic-design.jpg";
import ogImageVK from "assets/images/seo/vk/foundation-of-graphic-design.jpg";
// hooks
import useByHandPortfolio from "hooks/portfolio/useByHandPortfolio";
import useDesignByHandQuery from "hooks/graphql/useDesignByHandQuery";
// utilities
import generateCanonicalLink from "utilities/link-utils";
// constants
import PAGE_SLUGS from "constants/page-slugs";

const GraphicDesignByHandPage = () => {
  const prismicData = useDesignByHandQuery();
  const portfolio = useByHandPortfolio();
  const { data } = prismicData.course;
  const { data: seoData } = prismicData.page || {};
  const courseTitle = "Графдизайн руками";
  const courseDescription =
    "Направление графического дизайна, в котором мы научимся использовать физические материалы для создания макетов. Вы добавите в свои проекты искренности и теплоты.";

  return (
    <Layout>
      <SEO
        title={courseTitle}
        description={seoData?.seodescription?.text || courseDescription}
        keywords={["руками", "графдизайн", "graphdesign", "granich"]}
        ogImage={seoData?.seoimage?.url || ogImage}
        ogImageVk={seoData?.seoimage?.thumbnails?.vk?.url || ogImageVK}
        url={generateCanonicalLink(PAGE_SLUGS.graphicDesignByHand)}
      />
      <div className="section-top-block" />
      <ByHandOffer data={data} courseDescr={courseDescription} />
      <ByHandForWhom />
      <FoundationPortfolio
        courseTitle={data.coursetitle?.text}
        portfolio={portfolio}
        customTitle="Некоторые работы с мастер-класса"
        fixedHeight
      />
      <ByHandAuthors />
      <div id="participation-section" />
      <CoursePriceRange
        formId="ltForm9545352"
        priceRange={localData.priceRange}
        courseTitle={courseTitle}
        formPostUrl="https://school.granich.design/pl/lite/block-public/process-html?id=1715505854"
      />
      <PaymentChoices courseType="Мастер-класс" />
    </Layout>
  );
};

export default GraphicDesignByHandPage;
