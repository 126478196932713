import React from "react";
import { Container, Section, SectionTitle } from "components/style";
import TeamMember from "components/pages/index/team/teacher";
import teamData from "data/team";
import AuthorsWrapper from "./style";

const author = teamData.find(({ name }) => name === "Юлия Есинкина");
author.teacherCategory = "Автор";

const ByHandAuthors = () => (
  <Section>
    <Container>
      <SectionTitle>Курс ведёт:</SectionTitle>
      <AuthorsWrapper>
        <TeamMember
          teamMember={author}
          courseCard
          customDescription={
            <>
              <p>
                Проводит лекции, проверяет домашние задания, помогает
                докручивать проекты
              </p>
              <p>Выпускница НИУ ВШЭ «Коммуникационный дизайн»</p>
              <p>Старший дизайнер маркетинговых коммуникаций Мвидео</p>
            </>
          }
        />
      </AuthorsWrapper>
    </Container>
  </Section>
);

export default ByHandAuthors;
