import React, { useState } from "react";
import Modal from "react-responsive-modal";
import * as SC from "./style";

const ImageZoomComponent = ({ children, customClass }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        center
        closeIcon={<div />}
        open={isModalOpen}
        onClose={closeModal}
        focusTrapped={false}
        classNames={{
          overlay: "customOverlay",
          modal: `customModal image-zoom ${customClass}`,
        }}
      >
        <SC.StyledImageModal
          onClick={closeModal}
          style={{ position: "relative" }}
        >
          {children}
        </SC.StyledImageModal>
      </Modal>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={openModal}
        onClick={openModal}
        style={{ width: "100%" }}
      >
        {children}
      </div>
    </>
  );
};

export default ImageZoomComponent;
