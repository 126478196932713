import styled from "styled-components";

const AuthorsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1vw;

  .team-category {
    background: #ffa8e6;
    border-color: #ffa8e6;
    border-width: 0.1vw;
    font-weight: 500;
    color: white;
  }

  @media only screen and (max-width: 575px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 4vw;
  }
`;

export default AuthorsWrapper;
