import styled from "styled-components";
import { Descr, Wrapper } from "components/pages/course-page/offer/style";
import SVGTitle from "assets/svgs/graphic-design-by-hand/title.svg";
import ByHandLogo from "assets/svgs/graphic-design-by-hand/by-hand_logo.svg";
import Img from "gatsby-image";

export const StyledWrapper = styled(Wrapper)`
  position: relative;
  top: -2vw;
  padding-top: 5vw;

  @media only screen and (max-width: 575px) {
    padding-top: 7vw;
    top: 0;
  }
`;

export const Tags = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    margin-bottom: 0 !important;
  }

  @media only screen and (max-width: 575px) {
    padding: 0 3.5vw;
    margin-bottom: 2vw;
  }
`;

export const MobileCompositionWrapper = styled.div`
  @media only screen and (max-width: 575px) {
    min-height: 80vw;
    height: 80vw;
    width: 100%;
    position: relative;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    margin: 0;
  }
`;

export const StyledDescr = styled(Descr)`
  text-align: center;
  width: 46vw;
  margin-top: 1.5vw;

  @media only screen and (max-width: 575px) {
    width: 100%;
    margin-top: 0;
  }
`;

export const StyledMainIllustration = styled(Img)`
  width: 100%;
  border-radius: 1.5vw;

  @media only screen and (max-width: 575px) {
    border-radius: 3.5vw;
  }
`;

export const MainIllustrationWrapper = styled.div`
  padding: 0.5vw;
  background: white;
  border-radius: 1.5vw;
  // margin-bottom: 2vw;
  box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.15);
  transition: transform ease 0.2s;
  position: relative;
  z-index: 2;

  :hover {
    cursor: pointer;
    transform: scale(1.01);
  }

  @media only screen and (max-width: 575px) {
    margin-bottom: 4vw;
    margin-top: 4vw;
    border-radius: 3.5vw;
    padding: 1vw;
    box-shadow: 0 0vw 3vw rgba(0, 0, 0, 0.3);

    :hover {
      transform: none;
    }
  }
`;

export const StyledByHandLogo = styled(ByHandLogo)`
  width: 8.3vw;
  height: 8.3vw;

  @media only screen and (max-width: 575px) {
    width: 14vw;
    height: 14vw;
  }
`;

export const StyledMasterClassTitle = styled.h1`
  font-family: TT Travels Next Educational Bold;
  text-transform: uppercase;
  font-size: 2.4vw;
  text-align: center;
  font-weight: bold;
  color: black;
  line-height: 1.25;
  white-space: nowrap;
  font-size: 0.01vw;
  opacity: 0;

  span {
    font-family: TT Travels Next Educational Bold;
    text-transform: uppercase;
    font-size: 2.4vw;
    display: block;
    text-align: center;
    color: #ffa8e6;
    font-weight: bold;
  }

  @media only screen and (max-width: 575px) {
    font-size: 0.01vw;

    span {
      font-size: 4vw;
    }
  }
`;

export const TitleContent = styled.div`
  position: absolute;
  bottom: 1.2vw;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledSVGTitle = styled(SVGTitle)`
  width: 30vw;
  min-width: 30vw;
  margin-top: 1.5vw;

  @media only screen and (max-width: 575px) {
    width: 50vw;
    min-width: 50vw;
    margin-top: 3vw;
  }
`;
