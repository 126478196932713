/* eslint-disable */
import { useStaticQuery, graphql } from "gatsby";

const useByHandPortfolio = () => {
  const query = useStaticQuery(graphql`
    query byHandPortfolio {
      poster1: file(
        relativePath: { eq: "graphic-design-by-hand/portfolio/01.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster2: file(
        relativePath: { eq: "graphic-design-by-hand/portfolio/02.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster3: file(
        relativePath: { eq: "graphic-design-by-hand/portfolio/03.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster4: file(
        relativePath: { eq: "graphic-design-by-hand/portfolio/04.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster5: file(
        relativePath: { eq: "graphic-design-by-hand/portfolio/05.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster6: file(
        relativePath: { eq: "graphic-design-by-hand/portfolio/06.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster7: file(
        relativePath: { eq: "graphic-design-by-hand/portfolio/07.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const portfolio = Object.values(query);

  const data = [
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53572",
      src: portfolio[0].childImageSharp.fluid,
      author: "Вероника Стеценко",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53573",
      src: portfolio[2].childImageSharp.fluid,
      author: "Есинкина Юлия",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53574",
      src: portfolio[3].childImageSharp.fluid,
      author: "Есинкина Юлия",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa5ad4",
      src: portfolio[4].childImageSharp.fluid,
      author: "Дарья Гурова",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa5hfg",
      src: portfolio[5].childImageSharp.fluid,
      author: "Дарья Гурова",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53sd",
      src: portfolio[6].childImageSharp.fluid,
      author: "Вероника Стеценко",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa535722",
      src: portfolio[1].childImageSharp.fluid,
      author: "Дарья Гурова",
    },
  ];

  return data;
};

export default useByHandPortfolio;
