/* eslint-disable */
const PRICE_RANGE = {
  presentation: {
    title: "PDF-презентация",
    description:
      "Наглядная презентация, в которой бережно разжёвано всё главное, что вам следует знать мастер-классе",
  },
  courseVideo: {
    title: "Видеолекция",
    description:
      "Видеозапись разбора презентации. Для тех, кто хочет слушать и получать дополнительные комментарии по слайдам",
  },
  homework: {
    title: "Домашнее задание",
    description:
      "Домашнее задание поможет закрепить на практике понимание мастер-класса",
  },
  review: {
    title: "Индивидуальный разбор ДЗ",
    description:
      "Куратор запишет для вас видеоразбор ДЗ, где\r\nпоможет определить ошибки и даст рекомендации",
  },
  portfolio: {
    title: "Макет в портфолио",
    description:
      "Куратор поможет создать макет с нуля\r\nпо мастер-классу для вашего портфолио",
  },
};
const priceRange = [
  {
    pricesTitle: "Презентация",
    priceConditions: [
      {
        text: PRICE_RANGE.presentation.title,
        description: PRICE_RANGE.presentation.description,
        isActive: true,
      },
      {
        text: PRICE_RANGE.courseVideo.title,
        description: PRICE_RANGE.courseVideo.description,
        isActive: false,
      },
      {
        text: PRICE_RANGE.homework.title,
        description: PRICE_RANGE.homework.description,
        isActive: false,
      },
      {
        text: PRICE_RANGE.review.title,
        description: PRICE_RANGE.review.description,
        isActive: false,
      },
      {
        text: PRICE_RANGE.portfolio.title,
        description: PRICE_RANGE.portfolio.description,
        isActive: false,
      },
    ],
    price: 900,
    getcourseInputId: "4365894",
  },
  {
    pricesTitle: "Видеоформат",
    priceConditions: [
      {
        text: PRICE_RANGE.presentation.title,
        description: PRICE_RANGE.presentation.description,
        isActive: true,
      },
      {
        text: PRICE_RANGE.courseVideo.title,
        description: PRICE_RANGE.courseVideo.description,
        isActive: true,
      },
      {
        text: PRICE_RANGE.homework.title,
        description: PRICE_RANGE.homework.description,
        isActive: false,
      },
      {
        text: PRICE_RANGE.review.title,
        description: PRICE_RANGE.review.description,
        isActive: false,
      },
      {
        text: PRICE_RANGE.portfolio.title,
        description: PRICE_RANGE.portfolio.description,
        isActive: false,
      },
    ],
    price: 1900,
    getcourseInputId: "4365914",
  },
  {
    pricesTitle: "С заданием",
    priceConditions: [
      {
        text: PRICE_RANGE.presentation.title,
        description: PRICE_RANGE.presentation.description,
        isActive: true,
      },
      {
        text: PRICE_RANGE.courseVideo.title,
        description: PRICE_RANGE.courseVideo.description,
        isActive: true,
      },
      {
        text: PRICE_RANGE.homework.title,
        description: PRICE_RANGE.homework.description,
        isActive: true,
      },
      {
        text: PRICE_RANGE.review.title,
        description: PRICE_RANGE.review.description,
        isActive: true,
      },
      {
        text: PRICE_RANGE.portfolio.title,
        description: PRICE_RANGE.portfolio.description,
        isActive: false,
      },
    ],
    price: 2900,
    getcourseInputId: "4365919",
  },
  {
    pricesTitle: "Макет с нуля",
    priceConditions: [
      {
        text: PRICE_RANGE.presentation.title,
        description: PRICE_RANGE.presentation.description,
        isActive: true,
      },
      {
        text: PRICE_RANGE.courseVideo.title,
        description: PRICE_RANGE.courseVideo.description,
        isActive: true,
      },
      {
        text: PRICE_RANGE.homework.title,
        description: PRICE_RANGE.homework.description,
        isActive: true,
      },
      {
        text: PRICE_RANGE.review.title,
        description: PRICE_RANGE.review.description,
        isActive: true,
      },
      {
        text: PRICE_RANGE.portfolio.title,
        description: PRICE_RANGE.portfolio.description,
        isActive: true,
      },
    ],
    price: 3900,
    getcourseInputId: "4365922",
  },
];
export default { priceRange };
